function mobileMenu() {
    const hamburger = document.querySelector('.menu-burger .menu');
    const menu = document.querySelector('.menu-wrapper');
    const hasSubmenu = document.querySelectorAll('.menu-item-has-children');

    hamburger.addEventListener('click', function () {
        this.classList.toggle('opened');
        menu.classList.toggle('mobile-menu-open');
    });

    // hasSubmenu.forEach(function () {
    //     const submenu = this.querySelector('.sub-menu');
    //
    //     link.addEventListener('click', function () {
    //         this.classList.toggle('open');
    //         submenu.classList.toggle('sub-menu-open');
    //     })
    // });
}

export { mobileMenu }
