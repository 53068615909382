function interactiveImage() {

    function elementInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            left >= window.pageXOffset &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    }

    let fish_buttons = document.querySelectorAll('.fish-pulsing-button');
    if(fish_buttons.length) {
        for (let fish of fish_buttons) {
            fish.addEventListener('click', function () {

                if(this.classList.contains('active_fish')) {
                    for (let fishs of fish_buttons) {
                        fishs.classList.remove('active_fish');
                        fishs.nextElementSibling.classList.remove('active_fish');
                    }


                } else {
                    for (let fishs of fish_buttons) {
                        fishs.classList.remove('active_fish');
                        fishs.nextElementSibling.classList.remove('active_fish');
                    }


                    this.classList.add('active_fish');
                    this.nextElementSibling.classList.add('active_fish');
                }



                /*if(!elementInViewport(this.nextElementSibling)) {
                  let xPos = parseInt(this.nextElementSibling.style.left, 10);
                  let newXPos = xPos - 15;
                  this.nextElementSibling.style.left = newXPos + '%';
                } */
            });
        }
    }
}

export { interactiveImage }
