function pageHeader() {
    var image = document.querySelector('.page-header-img-video picture');
    var video = document.querySelector('.page-header-img-video video');

    if (video) {
        video.oncanplay = function () {
            // Bild ausblenden
            image.style.display = 'none';
            // Video anzeigen und abspielen
            video.style.display = 'block';
            video.play();
        }
    }


}


export { pageHeader }
