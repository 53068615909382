import domReady from '@roots/sage/client/dom-ready';
import "@lottiefiles/lottie-player";
import {mobileMenu} from "@scripts/modules/mobilemenu";
import {pageHeader} from "@scripts/modules/pageheader";
import {interactiveImage} from "@scripts/modules/interactive_image";
import {slider} from "@scripts/modules/slider";


/**
 * Application entrypoint
 */
domReady(async () => {
    mobileMenu();
    pageHeader();
    slider();
    interactiveImage();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
