import Splide from '@splidejs/splide/dist/js/splide.js';

function slider() {
    /****Product Slider****/
    document.querySelectorAll('.product-slider').forEach(carousel => new Splide(carousel, {
        type: 'slide',
        perPage: 4,
        // focus  : 'center',
        gap: "1.5rem",
        autoplay: false,
        arrows: false,
        breakpoints: {
            1024: {
                perPage: 3,
            },
            641: {
                perPage: 2,
            },
            410: {
                perPage: 1,
            },
        }
    }).mount());


    /****Topbar Slider****/
    document.querySelectorAll('.topbar-slider').forEach(carousel => new Splide(carousel, {
        type: 'slide',
        perPage: 1,
        focus  : 'center',
        autoplay: false,
        arrows: true,
        pagination: false,
    }).mount());
}

export { slider };
